* {
  transition: all 1s;
}
body {
  padding: 2px 2px 20px 2px;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100vh;
  background-color: rgb(240, 240, 240);
}
#App {
  height: 100vh;
}
#App .App {
  height: 100vh;
  display:flex;
  align-items: center;
}
.splash {
  display:flex;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  max-width: 100%;
  z-index: 1;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.splash > * {
  align-items: center;
}
.splash a {
  font-size: .8em;
  color: #324c7e;
  transition: 0.5s all;
  text-decoration: none;
}
.splash a:hover {
  text-shadow: 1px 1px 1px #324c7e;
}

@media screen and (min-width: 500px) {
  .splash {
    /* margin-top: 5%; */
  }
}

.splash img {
  max-width: 100%;
  width: auto;
  transition: 1s all;
}
.experience {
  margin-bottom: 1%;
  text-align: center;
  padding: 0%;
}
.experience h1 {
  font-size: 1.2rem;
  margin: 0 auto 10px auto;
  color: #324c7e;
}
.experience h2 {
  font-size: 1rem;
  margin: 0 auto 10px auto;
  color: #324c7e;
}
.experience p {
  margin: 0;
  line-height: 1.4rem;
}
.experience ul {
  text-align: center;
  list-style-type: none;
  margin: 0;
  padding: 0px;
}
.experience ul li {
  margin: 1px;
  padding: 1px;
}
.experience ul li a {
  color: #324c7e;
  transition: 0.5s all;
}
.experience ul li a:hover {
  color: #000;
}

p {
  color: #324c7e;
}

.visible {
}
.hidden {
  visibility: hidden;
}

.buttons {
  display: flex;
  background-color: #324c7e;
  padding: 2px;
  margin-bottom: 20px;
  justify-content: center;
}
.buttons button {
  background: none;
  border: none;
  flex-basis: 1;
}
.buttons button:hover {
  border: none;
}
.buttons .active {
  background: #fff;
  color: #324c7e;
}

.Portfolio {
  display: flex;
}
.portfolio-item {
  flex-grow: 1;
  width: 50%;
  flex-basis: 50%;
}

@media screen and (max-width: 480px) {
  .splash {
    height: auto;
    /*transform:rotate(-45deg);*/
    max-height: 40%;
    top: 0px;
    transition: 1s all;
    max-width: 100%;
    /*transform:rotate(90deg);*/
    transition: 1s all;
    font-size: 0.75rem;
    margin-top: 20%;
  }
  .splash img {
    max-width: 75%;
  }
  .experience h1 {
    font-size: 1rem;
  }
}

/* New Stuff */
#nav {
  padding: 2%;
  position: fixed;
  left: 0;
  top: 0;
}
#nav a {
  color: #324c7e;
  text-decoration: none;
}
#nav a:hover {
  padding: 1px;
  color: white;
  background-color: #324c7e;
}

.quotes {
  overflow-y: scroll;
}
.quotes #quotes {
  height: 100%;
  text-align: center;
  padding: 0 24% 0 24%;
}

/* Tetris */

body.tetris * {
}
body.tetris {
  background: #202020;
  color: #fff;
  font-family: sans-serif;
  font-size: 2em;
  text-align: center;
}
body.tetris canvas {
  border: solid 0.1em white;
}

.bodyLoaded {
  overflow-y: scroll;
}

/* Mastermind */
#root {
  margin: 0;
  padding: 0;
}
h1 {
  color: slategrey;
  text-align: center;
  margin-bottom: 1em;
  font-size: 1.5em;
}
h1.new-game {
  color: white;
}
.game {
  width: calc(28%);
  width: 100%;
  margin: 0 auto;
  /*border: 1px solid lightslategrey;*/
  /*background-color:lightgrey;*/
  border-radius: 25px;
}
@media (max-width: 1000px) {
  .game {
    width: calc(100%);
  }
}
@media (max-width: 768px) {
  h1 {
  }
  .game {
    margin-top: 45px;
    width: calc(90%);
    padding: 2%;
  }
}
button {
  width: auto;
  border: 1px solid lightslategray;
  margin: 0 auto;
  display: block;
  background-color: lightslategrey;
  color: white;
  padding: 10px;
  border-radius: 5px;
  transition: all 2s;
  text-transform: uppercase;
  font-size: 0.6em;
  transition: all 0.5s;
  cursor: pointer;
}
@media (max-width: 1023px) {
  button.cat-button {
    position: absolute;
    bottom: 10%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    z-index:100;
  }
}
@media (hover: hover) {
  button:hover {
    background-color: white;
    border: 1px solid lightslategrey;
    color: darkslategrey;
  }
}
button:active {
  color: lightslategrey;
  background-color: white;
}
.button-container {
  display: flex;
  justify-content: center;
  flex-basis: auto;
  width: 30%;
  margin: 5px auto;
}

.purple {
  background-color: purple;
}
.green {
  background-color: green;
}
.blue {
  background-color: blue;
}
.orange {
  background-color: orange;
}
.yellow {
  background-color: yellow;
}
.salmon {
  background-color: salmon;
}
.none {
  background-color: lightgrey;
}
.aqua {
  background-color: aqua;
}
.maroon {
  background-color: maroon;
}

form {
  text-align: center;
  width: auto;
  margin: 0 auto;
  display: block;
}
select {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 0em;
}
option {
  font-size: 1em;
}

h2 {
  margin: 0px 0;
  padding: 0;
  font-size: 1.2em;
  text-align: center;
  color: slategray;
}
.row {
  height: auto;
  margin-top: 10px;
  clear: both;
  position: relative;
  border-bottom: 1px solid lightslategrey;
}
ul.guesses {
  width: 80%;
  padding: 0;
  margin: 0 auto;
  text-align: center;
}
ul.guesses li {
  display: inline-block;
  list-style-type: none;
  width: 40px;
  height: 40px;
  border: 1px solid lightslategray;
  border-radius: 50%;
  margin-right: 2%;
  font-size: 0.7em;
  font-size: 0em;
}
@media (max-width: 800px) {
  ul.guesses {
  }
  ul.guesses li {
    width: 30px;
    height: 30px;
  }
}

ul.answer {
  margin: 0px 0;
  width: 100%;
  padding: 0;
  text-align: center;
}
ul.answer li {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid grey;
  font-size: 0.5em;
  font-size: 0em;
  margin-bottom: 10px;
  margin-right: 1%;
}

@media (max-width: 800px) {
  ul.answer {
  }
  ul.answer li {
    width: 10px;
    height: 10px;
  }
}
.red {
  background-color: red;
}
.white {
  background-color: white;
}
ul.answer li.none {
  background-color: grey;
}
form {
  clear: both;
}

.modal {
  right: 50%;
  margin-right: -45%;
  position: fixed;
  width: 90%;
  height: 90%;
  display: block;
  background-color: white;
  /*border:1px solid slategrey;*/
  border-radius: 15px;
  z-index: 1;
  transition: all 0.5s;
  overflow: hidden;
  color: slategrey;
}
.modal form {
  padding: 20px;
}
.modal form input {
  padding: 10px;
  border-radius: 6px;
  margin-top: 5px;
  margin-bottom: 20px;
  width: 20px;
  border: 1px solid lightslategrey;
  text-align: center;
  transition: all 2s;
}

.hidden {
  /*left:-100%;*/
  height: 0%;
  opacity: 0;
  transition: all 0.3s;
}
