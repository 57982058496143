h1 {
  color: #324c7e;
}
.play-button {
  font-size: 1.2rem;
  width: 50%;
  padding: 30px;
  border: none;
  background-color: #324c7e;
}
.songs button {
  background-color: #324c7e;
}
.songs button:hover,
.songs button:active {
  background-color: #fff;
}
.play-button:hover,
.play-button:active {
  border: none;
}
@media (min-width: 768px) {
  .play-button {
    width: 25%;
  }
}
@media (max-width: 300px) {
  .play-button {
    width: 100%;
  }
}
.audio-player {
  padding: 20px;
  position: relative;
}
.indicator-container {
  width: 50%;
  margin: 20px auto;
  position: relative;
  height: 0px;
  border: 1px solid #324c7e;
}
@media (min-width: 768px) {
  .indicator-container {
    width: 25%;
  }
}
.indicator {
  top: -5px;
  display: block;
  width: 5%;
  height: 10px;
  background-color: #ffffff;
  background-color: rgb(240, 240, 240);
  background-color: #324c7e;
  position: absolute;
  transition: 0.0001s all;
  outline: 1px solid #324c7e;
}
.current-song {
  text-align: center;
}
.songs {
  display: flex;
  flex-direction: column;
  /*flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;*/
}
p {
  margin-bottom: 30px;
  color: #324c7e;
}

/*
  @media (max-width:767px) {
    .songs {
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: wrap;
      padding-left:25%;
      padding-right:25%;
    }
  }
  */

.songs > * {
  margin-bottom: 20px;
}
